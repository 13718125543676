<script>
import ConfigItem from '@/views/Config/ConfigItem.vue'

export default {
    name: "SystemConfig",
    components: {ConfigItem},
    data() {
        return {
            configs: [],
            isLoaded: false,
            error: null,
            isSetLoading: false
        }
    },
    watch: {
        error() {
            if (this.error !== null) {
                this.$buefy.toast.open({
                    message: this.error.status === 500
                        ? 'Algo salió mal. Intente de nuevo más tarde.'
                        : this.error.status === 422
                            ? Object.values(this.error.data.errors)[0][0] || 'Algo salió mal. Intente de nuevo más tarde.'
                            : this.error.data.message || 'Algo salió mal. Intente de nuevo más tarde.',
                    type: 'is-danger',
                    position: 'is-top-right',
                    duration: 3000
                })
            }
        }
    },
    methods: {
        getConfigs() {
            this.$http('api/config/all', {
                method: 'GET',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                }
            })
                .then(({ data }) => {
                    this.configs.push(...data)
                })
                .catch(({ response }) => {
                    this.error = { ...response }
                })
                .finally(() => {
                    this.isLoaded = true
                })
        },
        setConfigValue({ key, value }) {
            this.isSetLoading = true

            this.$http('/api/config', {
                method: 'POST',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                data: {
                    properties: [{ key, value }]
                }
            })
                .then(({ data }) => {
                    data.forEach(newValue => {
                        const config = this.configs.find(config => config.key === newValue.key)

                        if (config !== undefined) {
                            config.value = newValue.value
                            config.updated_at = newValue.updated_at
                        }
                    })
                })
                .catch(({ response }) => {
                    this.error = { ...response }
                })
                .finally(() => {
                    this.isSetLoading = false
                })
        }
    },
    mounted() {
        this.getConfigs()
    }
}
</script>

<template>
    <div class="container mt-5 px-4">
        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Configuración del Sistema
                </h1>
            </div>
        </div>

        <div class="columns">
            <div class="column is-10 is-offset-1">
                <div
                    v-if="isLoaded"
                    class="box mb-6"
                >
                    <div class="columns is-mobile">
                        <div class="column">
                            <div
                                v-for="(config, index) in configs"
                                :key="`config-item-${config.key}`"
                            >
                                <ConfigItem
                                    :config="config"
                                    :is-loading="isSetLoading"
                                    @save="setConfigValue"
                                />

                                <div v-if="index !== configs.length - 1" class="line-divider"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <b-loading
                        is-full-page
                        :active="!isLoaded"
                        :can-cancel="false"
                        is-full-screen
                    />
                </div>
            </div>
        </div>
    </div>
</template>
