<script>
export default {
    name: "ConfigItem",
    props: {
        config: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: this.config.value,
            inputSize: window.innerWidth < 768
                ? 'is-small'
                : 'is-medium'
        }
    },
    computed: {
        inputType() {
            if (typeof this.config.value === 'number') {
                return {
                    type: this.config.enum === null
                        ? 'number'
                        : 'select',
                    enum: this.config.enum
                }
            }

            if (typeof this.config.value === 'string') {
                return {
                    type: this.config.enum === null
                        ? 'text'
                        : 'select',
                    enum: this.config.enum
                }
            }

            if (typeof this.config.value === 'boolean') {
                return {
                    type: 'checkbox',
                    enum: this.config.enum
                }
            }

            if (Array.isArray(this.config.value)) {
                return {
                    type: 'multiple',
                    enum: this.config.enum
                }
            }

            return {
                type: 'text',
                enum: this.config.enum
            }
        }
    },
    methods: {
        emitSaveEvent() {
            this.$emit('save', {
                key: this.config.key,
                value: this.model
            })
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.inputSize = window.innerWidth < 768
                ? 'is-small'
                : 'is-medium'
        })
    }
}
</script>

<template>
    <div class="is-flex is-flex-direction-column px-0 px-tablet-4">
        <div class="columns is-mobile is-flex-wrap-wrap">
            <div class="column is-9">
                <h2 class="subtitle has-text-primary-dark is-6 is-capitalized">
                    {{ config.name }}
                </h2>
            </div>

            <div class="column is-3">
                <div class="is-flex is-justify-content-flex-end is-align-items-center">
                    <b-tooltip
                        :label="config.visibility === 'public' ? 'Público' : 'Privado'"
                        type="is-dark"
                        position="is-bottom"
                    >
                        <b-icon
                            v-if="config.visibility === 'public'"
                            icon="unlock"
                            type="is-success"
                        />

                        <b-icon
                            v-else
                            icon="lock"
                            type="is-danger"
                        />
                    </b-tooltip>
                </div>
            </div>
        </div>

        <div class="columns is-mobile is-flex-wrap-wrap">
            <div class="column is-10 is-offset-1">
                <p class="has-text-grey-dark is-size-7 is-capitalized">
                    {{ config.description }}
                </p>
            </div>

            <div class="column pb-0 is-flex is-justify-content-center">
                <b-field
                    grouped
                >
                    <b-numberinput
                        v-if="inputType.type === 'number'"
                        v-model="model"
                        step="0.01"
                        :size="inputSize"
                        controls-position="compact"
                        controls-alignment="left"
                    />

                    <div class="control">
                        <b-button
                            type="is-success"
                            :size="inputSize"
                            icon-left="save"
                            @click="emitSaveEvent"
                        />
                    </div>
                </b-field>
            </div>

            <div class="column pt-0 is-12">
                <p class="has-text-centered has-text-grey is-size-7 is-italic">
                    Modificado:
                    {{ $moment(config.updated_at).fromNow() }}
                </p>
            </div>
        </div>
    </div>
</template>
