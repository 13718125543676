import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Users from '../views/Users.vue'
import Transactions from '../views/Transactions.vue'
import Accounts from '../views/Accounts.vue'
import Profile from '../views/Profile.vue'
import ShowUser from '../views/ShowUser.vue'
import ShowTransaction from '../views/ShowTransaction/ShowTransaction.vue'
import UpdateTransaction from '../views/ShowTransaction/UpdateTransaction.vue'
import AvatarEdit from '../views/AvatarEdit.vue'
import Rate from '../views/Rate.vue'
import Report from '../views/Report.vue'
import Admins from '../views/Admins/Admins.vue'
import Questions from '../views/Questions.vue'
import PreRegistration from '../views/PreRegistration.vue'
import ShowPre from '../views/ShowPre.vue'
import PaymaticoEntries from '../views/PaymaticoEntries.vue'
import Invoices from '../views/Invoice/Invoices.vue'
import ReportMonth from '../components/ReportMonth.vue'
import NewTransaction from '../views/NewTransaction/NewTransaction.vue'
import PaVenezuela from '../views/PaVenezuela/main.vue'
import MainView from '../views/Checkout/MainView.vue'
import CheckoutsView from '../views/Checkout/CheckoutsView.vue'
import OperationsView from '../views/Checkout/OperationsView.vue'
import { default as ExternalTransactionIndexView } from '../views/ExternalTransaction/IndexView.vue'
import { default as PaymentIndexView } from '../views/Payment/IndexView.vue'
import PaymentIndex from '../views/CryptoPocketPayment/PaymentIndex.vue'
import SystemConfig from '../views/Config/SystemConfig.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/new-transaction',
    name: 'NewTransaction',
    component: NewTransaction
  },
  {
    path: '/',
    redirect: {
      name: 'Dashboard'
    }
  },
  {
    path: '/iniciar-sesion',
    name: 'Login',
    component: Login
  },
  {
    path: '/usuarios',
    name: 'Users',
    component: Users
  }, 
  {
    path: '/transacciones',
    name: 'Transactions',
    component: Transactions
  },
  {
    path: '/nuestras-cuentas',
    name: 'Accounts',
    component: Accounts
  },
  {
    path: '/mi-perfil',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/usuario/:id',
    name: 'ShowUser',
    component: ShowUser
  },
  {
    path: '/transaccion/:ref',
    name: 'ShowTransaction',
    component: ShowTransaction
  },
  {
    path: '/transaccion/:ref',
    name: 'UpdateTransaction',
    component: UpdateTransaction
  },
  {
    path: '/editar-avatar',
    name: 'AvatarEdit',
    component: AvatarEdit
  },
  {
    path: '/tasa',
    name: 'Rate',
    component: Rate
  },
  {
    path: '/reporte-personalizado/:year',
    name: 'Report',
    component: Report,
    children: [
      {
        path: ':month',
        component: ReportMonth,
        name: 'ReportMonth'
      }
    ]
  },
  {
    path: '/administradores',
    name: 'Admins',
    component: Admins
  },
  {
    path: '/faqs',
    name: 'Questions',
    component: Questions
  },
  {
    path: '/pre-registro',
    name: 'PreRegistration',
    component: PreRegistration
  },
  {
    path: '/pre-registro/:id',
    name: 'ShowPre',
    component: ShowPre
  },
  {
    path: '/paymatico/entradas',
    name: 'PaymaticoEntries',
    component: PaymaticoEntries
  },
  {
    path: '/facturas',
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/pavenezuela',
    name: 'PaVenezuela',
    component: PaVenezuela
  },
  {
    path: '/cajas',
    component: MainView,
    children: [
      {
        path: '/',
        name: 'Checkout.MainView.CheckoutsView',
        component: CheckoutsView
      },
      {
        path: ':id',
        name: 'Checkout.MainView.OperationsView',
        component: OperationsView
      }
    ]
  },
  {
    path: '/transacciones-externas',
    name: 'ExternalTransaction.IndexView',
    component: ExternalTransactionIndexView
  },
  {
    path: '/pagos',
    name: 'Payment.IndexView',
    component: PaymentIndexView
  },
  {
    path: '/flusso/pagos',
    name: 'PaymentIndex',
    component: PaymentIndex
  },
  {
    path: '/configuracion',
    name: 'SystemConfig',
    component: SystemConfig
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
