<template>
    <b-navbar
        fixed-top
        type="is-dark">
        <template #brand>
            <b-navbar-item
                class="tablet-none"
                tag="div"
                @click="$parent.sidebar()"
                id="close-sidebar-btn"
                style="cursor: pointer;">
          <span
              class="icon is-size-5 is-medium"
              id="close-sidebar-icon">
            <i class="fas fa-bars"
               id="close-sidebar-icon-i"
            >
            </i>
          </span>
            </b-navbar-item>

            <b-navbar-item class="pl-mobile-0"
                           tag="div">
                <router-link :to="{name: 'Dashboard'}">
                    <img
                        :src="require('../assets/logo.png')"
                        alt="Logo de cambiatuseuros"
                        height="70"
                    >
                </router-link>
            </b-navbar-item>
        </template>

        <template #end>

            <b-navbar-item v-if="$root.isAuthenticate"
                           @click="$router.push({ name: 'Checkout.MainView.CheckoutsView' })"
                           tag="div"
                           title="Cajas"
            >
                <span id="navbar-checkout"
                      class="is-clickable"
                >
                    <b-icon icon="cash-register"
                            size="is-medium"
                    />
                </span>
            </b-navbar-item>

            <b-navbar-item v-if="$root.isAuthenticate"
                           tag="div"
                           title="Mensajes"
            >

                <b-dropdown aria-role="list"
                            :mobile-modal="false"
                            position="is-bottom-left"
                            scrollable
                            :max-height="350">

                    <template #trigger>
                    <span
                        id="navbar-messages"
                        class="is-clickable">
                    <b-icon
                        icon="comment-alt"
                        size="is-medium"
                    >
                    </b-icon>

                    <b-tag
                        type="is-danger"
                        rounded
                        class="counter-messages"
                        v-if="$root.unreadedMessages > 0">
                        {{ $root.unreadedMessages }}
                    </b-tag>
                    </span>
                    </template>

                    <b-dropdown-item
                        class="px-4"
                        custom>
                        <b-input
                            v-model="filterMessages"
                            expanded
                            placeholder="Buscar por nombre"
                            type="search"
                            icon="search"
                            size="is-small"
                        >
                        </b-input>
                    </b-dropdown-item>

                    <div
                        v-for="(item, index) in conversations"
                        :key="'conversation-'+item.id"
                        style="width: 300px">
                        <b-dropdown-item
                            @click="$root.openChat(item.id, item.name + ' ' + item.lastname)">
                            <ul>
                                <li class="mb-0 text-desborder">
                    <span class="active-circle has-background-success"
                          v-if="$root.usersConnected[item.id] !== undefined"
                    >
                    </span>
                                    <b>{{ item.name + ' ' + item.lastname }}</b>
                                </li>
                                <li class="mb-0 has-text-grey-light text-desborder"
                                    v-if="item.unreaded > 0">
                    <span class="active-circle mr-1"
                          v-if="$root.usersConnected[item.id] !== undefined"
                    >
                    </span>
                                    <b-tag type="is-danger" rounded>{{ item.unreaded }}</b-tag>
                                    Mensaje<span v-if="item.unreaded > 1">s</span> sin leer
                                </li>
                            </ul>
                        </b-dropdown-item>

                        <b-dropdown-item separator
                                         v-if="index !== ($root.conversations.length - 1)"
                        >
                        </b-dropdown-item>
                    </div>

                </b-dropdown>

            </b-navbar-item>

            <b-navbar-item v-if="$root.isAuthenticate"
                           tag="div"
                           title="Notificaciones"
            >

                <b-dropdown aria-role="list"
                            position="is-bottom-left"
                            :mobile-modal="false"
                            max-height="400px"
                            scrollable
                            :close-on-click="false">

                    <template #trigger>
              <span id="navbar-notification"
                    class="is-clickable">
                <b-icon icon="bell"
                        size="is-medium"
                >
                </b-icon>

                <b-tag type="is-danger"
                       rounded
                       class="counter-notification"
                       v-if="$root.unreadedNotifications > 0">
                  {{ $root.unreadedNotifications }}
                </b-tag>
              </span>
                    </template>

                    <div style="max-width: 360px;"
                         :class="{ 'has-background-primary-light': !item.readed }"
                         v-for="(item, index) in $root.notifications.content"
                         :key="'notification'+ item.id">

                        <b-dropdown-item class="pr-4"
                                         @click="openNotification(item, index)">

                            <p class="mb-0 has-text-centered">
                                {{ item.title }}
                            </p>
                            <p class="mb-0 text-desborder">
                                {{ item.body }}
                            </p>
                            <p class="mb-0 is-size-7 has-text-grey has-text-centered">
                                {{ $moment(item.created_at).fromNow() }}
                            </p>

                        </b-dropdown-item>

                        <b-dropdown-item separator
                                         v-if="index !== ($root.notifications.content.length - 1)"
                        >
                        </b-dropdown-item>

                    </div>

                    <div style="max-width: 360px"
                         v-if="$root.notifications.next != null">
                        <b-dropdown-item class="pr-4"
                                         @click="$root.offsetNotifications()">
                            <p class="mb-0 is-size-7 has-text-centered">
                                <b>Ver todas</b>
                            </p>
                        </b-dropdown-item>
                    </div>

                </b-dropdown>

            </b-navbar-item>

            <b-navbar-item v-if="$root.isAuthenticate"
                           tag="div">

                <b-dropdown aria-role="list"
                            :mobile-modal="false"
                            position="is-bottom-left"
                >

                    <template #trigger
                    >
                        <div class="is-flex is-align-items-center is-clickable">

                            <b-skeleton
                                animated
                                circle
                                width="2rem"
                                height="2rem"
                                v-if="$root.profile.thurnail === null"
                            >
                            </b-skeleton>

                            <avatar
                                v-else-if="$root.profile.thurnail === false"
                                :size="34"
                                :username="$root.user.name"
                                :initials="$root.user.name.charAt(0) + $root.user.lastname.charAt(0)"
                                color="#f5f5f5"
                            >
                            </avatar>

                            <img :src="$root.profile.thurnail"
                                 alt="Avatar"
                                 id="avatar-menu"
                                 v-else>

                            <b-icon
                                icon="caret-down">

                            </b-icon>
                        </div>

                    </template>

                    <b-dropdown-item @click="$router.push({ name: 'Profile' })">
                        <b-icon
                            icon="user">

                        </b-icon>

                        Mi perfil

                    </b-dropdown-item>

                    <b-dropdown-item @click="$router.push({ name: 'Admins' })"
                                     v-if="$root.isAdminRole">
                        <b-icon
                            icon="users">

                        </b-icon>

                        Administradores

                    </b-dropdown-item>

                    <b-dropdown-item @click="$router.push({ name: 'Payment.IndexView' })">
                        <b-icon icon="hand-holding-usd"
                        >
                        </b-icon>

                        Pagos
                    </b-dropdown-item>

                    <b-dropdown-item @click="$router.push({ name: 'ExternalTransaction.IndexView' })">
                        <b-icon icon="shuttle-van"
                        >
                        </b-icon>

                        Transacciones Externas
                    </b-dropdown-item>

                    <b-dropdown-item @click="$router.push({ name: 'SystemConfig' })">
                        <b-icon icon="cog" />

                        Configuración del Sistema
                    </b-dropdown-item>

                    <hr class="dropdown-divider" aria-role="menu-item">

                    <b-dropdown-item
                        @click="$root.logout()">
                        <b-icon
                            icon="sign-out-alt">

                        </b-icon>

                        Cerrar sesión

                    </b-dropdown-item>

                </b-dropdown>

            </b-navbar-item>

        </template>

    </b-navbar>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
    data() {
        return {
            filterMessages: null
        }
    },
    computed: {
        conversations() {
            if (this.filterMessages === null || this.filterMessages === '') {
                return this.$root.conversations
            } else {
                let output = []
                let regex = new RegExp(this.filterMessages, 'i')

                this.$root.conversations.forEach(item => {
                    if (regex.test(item.name) || regex.test(item.lastname)) {
                        output.push(item)
                    }
                })

                return output
            }
        }
    },
    methods: {
        openNotification(item, index) {
            this.$root.markNotification(item.id)

            this.$root.notifications.show = index
            this.$root.notifications.modal = true
        }
    },
    name: 'NavBar',
    components: {
        Avatar
    }
}
</script>

<style lang="scss">
#avatar-menu {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgb(220, 220, 255);
}

#navbar-messages {
    position: relative;
    padding: 0 !important;

    .counter-messages {
        position: absolute;
        top: -1rem;
        right: -.5rem;
    }
}

#navbar-notification {
    position: relative;
    padding: 0 !important;

    .counter-notification {
        position: absolute;
        top: -1rem;
        right: -.5rem;
    }
}

.active-circle {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: .5rem;
}
</style>